<template>
  <div v-bind:style="styles">
    <div v-if="!viLoader">
      <div class="row">
        <div class="col-lg-6">
          <div class="white-box m-2 white-box1">
            <span
              v-for="(data, index) in teacherList"
              :key="index"
              style="width: 74px"
            >
              <div
                class="no-blu no-cirlce"
                @click="openChat(data)"
                style="margin: 1%; float: left"
              >
                {{ data.firstName.charAt(0) }}
                <span v-if="data.message.length > 0" 
                        style="top: -17px;
                              right: 0px;
                              position: relative;
                              border-radius: 50%;
                              background: rgb(110 255 0 / 85%);
                              min-width: 2em;
                              font-weight: bold;
                              font-size: 42%;
                              padding: 0.6em;"
                        >{{  data.message.length  }}</span>

                <!-- <span
                  v-if="data.message.length > 0"
                  style="position: relative; color: cyan"
                  >{{ data.message.length }}</span
                > -->
                <div
                  style="
                    color: #e72424;
                    width: 65px;
                    margin-top: 9px;
                    font-size: initial;
                  "
                >
                  {{ data.firstName }}
                </div>
              </div>
            </span>
          </div>
        
        </div>
        <div class="col-lg-6" v-if="showMsg">
          <div class="contact-form">
            <!-- <span class="close">&times;</span> -->
            <h2>
              {{ teacherDetails.firstName + " " + teacherDetails.lastName }}
            </h2>
            <br />
            <!-- <div class="widjet"> -->

            <!-- <div class="widjetcontent">
              <div class="mt-4 mr-2"> -->

            <!-- <div class="chat-container" style="margin-top: -32px;" id="chat-container"> -->
            <div
              class="chat-container"
              id="chat-container"
              style="margin-top: -32px"
            >
              <div
                v-for="(group, day) in Object.keys(groupedStudentMessages)"
                :key="day"
              >
                <div>
                  <div style="text-align: center">
                    <span
                      style="
                        border-radius: 30px;
                        background-color: #dbcbcb;
                        font-size: 17px;
                      "
                      >{{ formatDayHeader(group) }}</span
                    >
                  </div>
                  <div
                    v-for="message in groupedStudentMessages[group]"
                    :key="message._id"
                  >
                    <div
                      class="message"
                      v-if="message.fromUserType == 'Student'"
                    >
                      <div class="message sender-message" style="width: 300px;">
                        {{ message.message }}
                        <div class="timestamp">
                          {{ formatTime12Hour(message.date) }}
                        </div>
                      </div>
                    </div>
                    <div class="message receiver-message" v-else style="width: 300px;">
                      <div>{{ message.message }}</div>
                      <div class="timestamp">
                        {{ formatTime12Hour(message.date) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <textarea
                  style="width: 100%"
                  id="messageInput"
                  v-model="studentMessage"
                  class="message-input"
                  @keyup.enter="handleKeyPress"
                  placeholder="Type your message..."
                ></textarea>
              </div>
              <div class="col-lg-2" style="text-align: center">
                <button
                  style="margin-top: 12px; padding: 19px;margin-left: -5px;"
                  class="send-button"
                  @click.prevent="sendToStudent"
                >
                  Send
                </button>
                
                
              </div>
              
              <div class="col-lg-2" style="text-align: center">
                
                <button
                  style="margin-top: 12px; padding: 19px;margin-left: -33px;background-color: gray;"
                  class="send-button"
                  @click.prevent="closeChat"
                >
                  Cancel
                </button>
                
              </div>
              <div class="col-lg-1" style="text-align: center">
                <li class="addropdown">
              <span
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
              <button
                  style="margin-top: 12px; padding: 19px;margin-left: -33px;background-color: gray;"
                  class="send-button"
                 
                >
                  ...
                </button> 
              </span>
                <div class="addropdown-content wow bounceIn">
                <div class="arrow-up"></div>
                <div class="addropmenu" style="background-color: #dbefe3;width: 311px;">
                  <span class="usernamemob">.sss..</span
                  >
                  <label class="web-custradio" style="margin-right:10px;"
                  >Press enter to send message
                  <input
                    type="radio"
                    name="regRadio"
                    @change="showOptions"
                    v-model="isEnterBtnEnable"
                    value="enter"
                   
                  />
                  <span class="web-checkmark"></span>
                </label>
                <label class="web-custradio" 
                  >Click send to send message
                  <input
                    type="radio"
                    name="regRadio"
                    @change="showOptions"
                    v-model="isEnterBtnEnable"
                    value="send"
                   
                  />
                  <span class="web-checkmark"></span>
                </label>
                  <!-- <a><i class="fa fa-user pr-2" aria-hidden="true"></i>Profile</a>
                  <a><i class="fa fa-user pr-2" aria-hidden="true"></i>Profile</a>
                  <a><i class="fa fa-user pr-2" aria-hidden="true"></i>Profile</a> -->
                 
                </div>
              </div>
            </li>
                <!-- <button
                  style="margin-top: 12px; padding: 19px;margin-left: -33px;background-color: gray;"
                  class="send-button"
                  @click.prevent="showOptions"
                >
                  ...
                </button> -->
                <!-- <div class="three-dots" @click="showOptions"></div> -->
                
              </div>
              <!-- <div v-if="showOptionsMenu" class="options-menu">
    
      <div>Option 3</div>
      <div>Option 3</div>
      <div>Option 3</div>
    </div> -->
            </div>
            <!-- <div v-for="(msg, index) in teacherMessageList" :key="index" >
                    <div class="message sender-message" v-if="msg.fromUserType == 'Student'">
                        <p>{{ msg.message }}</p>
                        <div class="timestamp">{{ msg.date | dateFormat}}</div>
                      </div>

                      <div class="message receiver-message" v-else>
                        <p>{{ msg.message }}</p>
                        <div class="timestamp">{{ msg.date | dateFormat}}</div>
                      </div>
                    </div> -->

            <!-- </div> -->
            <!-- <div>
                        <textarea
                          style="width: 86%"
                          v-model="studentMessage"
                          class="message-input"
                          placeholder="Type your message..."
                        ></textarea
                        ><button class="send-button" @click.prevent="sendToStudent">Send</button>
                      </div> -->
          </div>
        </div>
      </div>
      <div></div>
      <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="
          width: 100%;
          z-index: 111;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 1rem;
        "
      />
    </div>
    <div></div>
  </div>
</template>
<script>

import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import Select from "vue-select";
import "vue-select/dist/vue-select.css";
import { socket } from "../../configuration/socketio";

export default {
  name: "candidates",
  data() {
    return {
    //  enableSendBtn: true,
      isEnterBtnEnable: 'send',
      viLoader: false,
      teacherList: [],
      teacherMessageList: [],
      teacherDetails: {},
      showMsg: false,
      studentMessage: "",
    };
  },
  created() {
    this.initCompent();

    socket.on("new_mesage_teacher", (message) => {
      
      if (message) {
        if (this.teacherDetails && this.teacherDetails._id == message.teacher) {
          this.teacherMessageList.push(message);

          let obj = {
            details: {
              userId: message.userId,
              teacher: message.teacher,
            },
            data: {
              isStudentView: true,
            },
          };

          socket.emit("student-message-read-teacher", obj);

          setTimeout(() => {
            var chatContainer = document.getElementById("chat-container");
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }, 100);
        } else {
          for (let x of this.teacherList) {
            if (message.teacher == x._id) {
              x.message.push(message);
            }
          }
        }
      }
    });
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    checkUnreadMessageCount() {
      let data = this.teacherMessageList.filter((x) => !x.isStudentView);
      return data.length;
    },
    groupedStudentMessages() {
      // Group messages by day
      const grouped = {};
      this.teacherMessageList.forEach((message) => {
        const day = this.formatDay(message.date);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });
      return grouped;
    },
  },
  watch: {},
  methods: {
    

    async initCompent() {
      await this.getStudentDetails();
     // let userData = secureUI.sessionGet("user");
      let data = localStorage.getItem('messageAction');
      this.isEnterBtnEnable = data || 'send'
    },
    formatTime12Hour(data) {
      let date = new Date(data);
      // Extract hours, minutes, and AM/PM indicator
      const hours = date.getHours() % 12 || 12; // Ensure 12-hour format
      const minutes = date.getMinutes();
      const period = date.getHours() < 12 ? "AM" : "PM";

      // Format as HH:MM AM/PM
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${period}`;

      return formattedTime;
    },

    async getStudentDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getClassAssignedTeachersDetaiils/${this.$route.params.id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.teacherList = response.data || {};
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    formatDayHeader(day) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (day === this.formatDay(today)) {
        return "Today";
      } else if (day === this.formatDay(yesterday)) {
        return "Yesterday";
      } else {
        return day;
      }
    },
    formatDay(timestamp) {
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    formatTime(sss) {
      let timestamp = new Date(sss);
      if (timestamp) {
        return `${timestamp.getHours()}:${
          timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()}`;
      } else {
        return "";
      }
    },
    openChat(data) {
      (this.teacherDetails = data), (this.showMsg = true);

      this.getStudentMessageList(this.teacherDetails._id);

      if (data.message.length > 0) {
        this.getReadMessageList(this.teacherDetails._id);
      }

      setTimeout(() => {
        var chatContainer = document.getElementById("chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 400);
    },
    closeChat(){
      this.teacherDetails = {}
      this.showMsg = false
    },
    handleKeyPress(event) {

      if (event.key === 'Enter' && this.isEnterBtnEnable == 'enter') {
        this.sendToStudent();
      }
    },
    showOptions() {
   
      this.updateUser()
      localStorage.setItem("messageAction",this.isEnterBtnEnable);

    },
    async updateUser() {
    
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let requestBody = {
              _id: userData.userDetails._id,
              isEnterBtnEnable: this.isEnterBtnEnable
          };
          const response = await ViService.viXPost(
            "/user/updateMessageUser",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
           
          } else {
            this.$toasted.error(response.message);
          }
        
      }
    },
    async sendToStudent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          student: null,
          userId: userData.userDetails._id,
          teacher: this.teacherDetails._id,
          message: this.studentMessage,
          fromUserType: "Student",
          isTeacherView: false,
          isStudentView: true,
          date: new Date(),
        };

        socket.emit("student-message-teacher", obj);

        this.teacherMessageList.push(obj);
        this.studentMessage = "";
        setTimeout(() => {
          var chatContainer = document.getElementById("chat-container");
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);

        // const response = await ViService.viXPost("/home/saveMessageStudentToTeacher",obj,userData.token);

        // if (response.isSuccess) {
        //   this.studentMessage = ''
        //   this.getStudentMessageList(this.teacherDetails._id)
        // this.$toasted.success(response.message);
        // } else {
        // this.$toasted.error(response.message);

        // }
      }
    },

    async getStudentMessageList(teacherId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/home/getStudentTeacherMessage/${teacherId}/${userData.userDetails._id}`,
          userData.token
        );
        if (response.isSuccess) {
          this.teacherMessageList = secureUI.secureGet(response.data);
          setTimeout(() => {
            var chatContainer = document.getElementById("chat-container");
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }, 100);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getReadMessageList(teacherId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          details: {
            userId: userData.userDetails._id,
            teacher: teacherId,
          },
          data: {
            isStudentView: true,
          },
        };

        socket.emit("student-message-read-teacher", obj);

        for (let x of this.teacherList) {
          if (teacherId == x._id) {
            x.message = [];
          }
        }
      }
    },
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", this.$route.path);
      //  this.$router.go(-1)
      this.$router.push({ path: data });
    },
    navigatetolink(value) {
      window.open(value, "_blank");
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    sendMessage(){
      console.log("check")
    }
  },
  components: {
    "v-select": Select,
    ViSpinner,
  },
};
// Handle "Enter" key press
// document.getElementById('messageInput').addEventListener('keydown', (event) =>{
//     if (event.key === 'Enter') {
//      // event.preventDefault(); // Prevent newline in the input field

//       // Call your method (e.g., sendMessage) here
//       this.sendMessage();
//     }
//   });

  

</script>
<!-- <script>
document.addEventListener('keypress', (event)=>{

  // event.keyCode or event.which  property will have the code of the pressed key
  let keyCode = event.keyCode ? event.keyCode : event.which;
  
  // 13 points the enter key
  if(keyCode === 13) {
    // call click function of the buttonn 
    //btn.click();
  }
    
  });
</script> -->
<style>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.chat-container {
  margin-top: -9px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  height: 250px; /* Set a fixed height for the chat container */
  overflow-y: scroll; /* Enable vertical scrolling */
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  clear: both;
  overflow: hidden;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.user-message {
  background-color: #e0f7fa;
}

.other-message {
  background-color: #fff;
}
.sender-message {
  background-color: #e0f7fa;
  float: right;
}

.receiver-message {
  background-color: #e9d1d1;
  float: left;
}
.message-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: none;
}

.send-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.timestamp {
  font-size: 12px;
  float: right;
  color: #888;
  margin-top: 5px;
  text-align: right;
}
.three-dots {
  width: 20px;
  height: 20px;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.options-menu {
  margin-top: 5px;
  padding: 5px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
}

.options-menu div {
  cursor: pointer;
  padding: 5px;
}
@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
  #no-print,
  #no-print * {
    display: none !important;
  }
}
</style>
